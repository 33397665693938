@font-face {
    font-family: "Suisse Int'l"; /* Name you give to the font */
    src: url('../../fonts/fonnts.com-Suisse_Intl_Regular.ttf') format('opentype');
    font-weight: normal;
    font-style: normal;
}
.contactus_all{
    background: #060606;
}
.contact_us_des h2{
    text-align: center;
    font-family: "Open Sans";
font-size: 52px;
font-style: normal;
font-weight: 700;
line-height: normal;
background: linear-gradient(90deg, #FFF 0%, #D0B4F1 100%);
background-clip: text;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
padding: 24px;
}
.contact_us_des p{
    color: #EFEFEF;
text-align: center;
width: 732px;
margin: 0 auto;
font-family: "Suisse Int'l";
font-size: 17px;
font-style: normal;
font-weight: 400;
line-height: 27px; /* 177.778% */
}
.form_container{
    /* width: 480px; */
    display: flex;
    align-items: center;
    justify-content: center;
   
}
/* .form_container input{
    display: flex;
    height: 44px;
    padding: 13px 24px;
    align-items: center;
    gap: 10px;
    border-radius: 8px;
border: 0.8px solid rgba(255, 255, 255, 0.211) !important;
background: #0A0A0A;
} */
.form_container ::placeholder{
    color: #9B9B9B;
    /* text-align: center; */
    font-family: "Suisse Int'l";
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 27px; /* 180% */
}
.form_container label{
    color: #EFEFEF;
    /* font-family: "Suisse Int'l"; */
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px; /* 213.333% */
    width: 50%;
}
.form_container button{
    border-radius: 8px;
    border-top: 0.8px solid rgba(161, 173, 252, 0.00);
    border-bottom: 0.8px solid rgba(161, 173, 252, 0.00);
    background: #463B81;
    box-shadow: 0px 0px 20px 0px rgba(152, 107, 230, 0.50);
    display: flex;
width: 480px;
height: 44px;
padding: 10px 20px;
justify-content: center;
align-items: center;
gap: 7px;
color: #FFF;
/* font-family: "Suisse Int'l"; */
font-size: 17px;
font-style: normal;
font-weight: 400;
line-height: normal;
}
.form_container{
    display:flex;
    justify-content: center;
padding:40px;
align-items: center;
gap: 10px;
flex-shrink: 0;
border-radius: 24px;
background: #111;
width: 550px;
margin: 0 auto;
margin-top: 24px;
}
.form-select{
    display: flex;
width: 100%;
height: 48px;
padding: 13px 24px;
justify-content: space-between;
align-items: center;
border-radius: 8px;
border: 0.8px solid rgba(255, 255, 255, 0.07) !important;
background: #0A0A0A;

}
.form-message{
    display: flex;
width: 100%;
height: 120px;
padding: 13px 24px;
justify-content: space-between;
align-items: center;
border-radius: 8px;
border: 0.8px solid rgba(255, 255, 255, 0.211) !important;
background: #0A0A0A;
outline: none;
}
.terms-container {
    display: flex;
    align-items: start;
    gap: 20px;
    padding-bottom: 24px;
  }
 
  .custom-checkbox {
    width: 24px !important;
    height: 24px !important;
    border-radius: 4px !important;
    border: 0.8px solid rgba(255, 255, 255, 0.0) !important;
    background: #0A0A0A !important;
  }
  .form_container option{
    color: var(--Primary-Base-White, #FFF);
    font-family: "Suisse Int'l";
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 27px; /* 180% */
  }
  .form_container select{
    color: white;
    display: flex;
    height: 44px;
    padding: 0px 24px;
    align-items: center;
    gap: 10px;
    border-radius: 8px;
background: #0A0A0A;
  }
 .form_control_input{
display: flex;
width: 100%;
height: 44px;
padding: 13px 24px;
align-items: center;
gap: 10px;
border-radius: 8px;
border: 0.8px solid rgba(255, 255, 255, 0.07) !important;
background: #0A0A0A;
 }
 .form_control_input:placeholder-shown{
  color: #9B9B9B;
font-family: "Suisse Int'l";
font-size: 15px;
font-style: normal;
font-weight: 400;
line-height: 27px; /* 180% */
 }
.form_control_input:focus{
    background-color: #0A0A0A !important;
    color: white !important;
    outline: none;
}

.file-upload-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 480px;
    height: 180px;
    padding: 13px 24px;
    border-radius: 8px;
    border: 0.8px solid rgba(255, 255, 255, 0.0);
    background: #0A0A0A;
    position: relative;
    overflow: hidden;
  }
  
  .upload-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
  }
  
  .upload-text {
    text-align: center;
  }
  
  .browse-files {
    color: #FFF;
    font-family: "Suisse Int'l", sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 0;
    padding-bottom: 4px;
  }
  
  .drag-drop-text {
    color: #9B9B9B;
    font-family: "Suisse Int'l", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  
  .file-input {
    position: absolute;
    width: 100%;
    height: 130px !important;
    top: 0;
    left: 0;
    opacity: 0;
    cursor: pointer;
  }
  
  @media screen and (max-width: 800px) {
    .form_container label{
      color: #EFEFEF;
      font-family: "Suisse Int'l";
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 23px; /* 176.923% */
      width: 100%;
  }
  .form_container{
    padding: 16px;
  }
.contact_us_des{
    width: 100%;
    margin: 0 auto;
    text-align: center;
}
    .contact_us_des h2{
        text-align: center;
font-family: "Open Sans";
font-size: 22px;
font-style: normal;
font-weight: 700;
line-height: normal;
    }
    .contact_us_des p{
        color: #EFEFEF;
text-align: center;
font-family: "Suisse Int'l";
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 24px; /* 171.429% */
width: 90%;
    }
    .form_container{
      width: 90%;
    }
    .form-select{
      width: 100%;
    }
    .file-upload-container{
      width: 100%;
    }
    .form_container button{
      width: 100%;
    }
    .input_sec{
      display: block;
    }
    .input_sec input{
      width: 100%;
      margin-bottom: 8px;
    }
  }